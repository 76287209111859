import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@chakra-ui/react';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const PrivacyPolicy: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground url={url}>
        <PageTitle subtitle="" title="Privacy Policy" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">
            <strong>PRIVACYBESCHERMING</strong>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDENTIFICATIE</Text2>
          <Text3 as="p">
            <ul>
              <li>
                <strong>Verantwoordelijke voor de verwerking</strong>: Ironhack
                Group INC Sucursal en España (hierna te noemen: “Ironhack”)
              </li>
              <li>
                <strong>Belastingnummer [CIF]</strong>:<strong> </strong>
                W4009352H
              </li>
              <li>
                <strong>Hoofdkantoor</strong>: C/ CASTELLÓ, 115, BAJOS 7 - 28006
                MADRID
              </li>
              <li>
                <strong>Data Protection Officer (DPO)</strong>: De Gebruiker kan
                contact opnemen met de functionaris voor gegevensbescherming -
                DPO - door zich schriftelijk te wenden tot het hoofdkantoor van
                Ironhack, ter attentie van "Data Protection Delegate", of per
                e-mail naar{' '}
                <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INFORMATIE EN INSTEMMING</Text2>
          <Text3 as="p">
            Met het aanvaarden van dit Privacybeleid, verklaart de Gebruiker
            zich voldoende ingelicht en geeft hij in vrijheid, nadrukkelijk,
            ondubbelzinnig en voldoende geïnformeerd zijn toestemming voor de
            verwerking door Ironhack van de persoonlijke gegevens die hij
            verstrekt via de formulieren op de website met URL{' '}
            <a href="https://www.ironhack.com/nl">www.ironhack.com</a> (hierna
            de "Website").
          </Text3>
          <Text3 as="p">
            Ironhack zal niet verantwoordelijk zijn voor de verwerking van
            gegevens via websites die niet onder de verantwoordelijkheid van
            Ironhack vallen.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            VERPLICHTING OM DE GEVRAAGDE GEGEVENS TE VERSTREKKEN
          </Text2>
          <Text3 as="p">
            De gegevens die in de formulieren van de website worden gevraagd
            zijn in het algemeen verplicht (tenzij in het betreffende veld
            anders is aangegeven) voor de gestelde doelen. Als deze gegevens
            niet of niet correct worden verstrekt, zal niet kunnen worden
            voldaan aan het verzoek van de gebruiker, hoewel de inhoud van de
            website nog altijd vrij zal kunnen worden bekeken.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            MET WELK DOEL VERWERKT IRONHACK DE PERSOONLIJKE GEGEVENS VAN DE
            GEBRUIKER EN VOOR HOE LANG BLIJVEN ZE BEWAARD
          </Text2>
          <Text3 as="p">
            De persoonlijke gegevens die door de gebruiker worden verstrekt{' '}
            <strong>via het contactformulier op de Website</strong>, worden door
            Ironhack verwerkt voor de volgende doeleinden:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Het beantwoorden van vragen, suggesties en klachten die de
                gebruiker aan Ironhack toestuurt. Uw gegevens worden voor dit
                doel bewaard zolang uw vraag, suggestie en/of klacht in
                behandeling is, en voorts daarna tot het moment waarop eventuele
                aansprakelijkheden die voortvloeien uit uw bericht zijn
                vervallen.{' '}
              </li>
            </ul>
            De persoonlijke gegevens die door de gebruiker worden verstrekt{' '}
            <strong>
              bij de aanmelding voor de commerciële nieuwsbrief van de Website
            </strong>
            , worden door Ironhack verwerkt voor de volgende doeleinden:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                De verwerking van de aanmelding voor de commerciële nieuwsbrief,
                om de gebruiker op de hoogte te kunnen houden van nieuwe
                evenementen en het laatste nieuws van Ironhack. Uw gegevens
                worden bewaard totdat u uw toestemming intrekt.{' '}
              </li>
            </ul>
            De persoonlijke gegevens die door de gebruiker worden verstrekt{' '}
            <strong>
              via het inschrijfformulier voor de cursussen van Ironhack
            </strong>
            , worden door Ironhack verwerkt voor de volgende doeleinden:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                De administratieve afhandeling van uw inschrijving en de nodige
                verrichtingen voor de selectie van de studenten. Uw gegevens
                worden voor dit doel bewaard gedurende de tijd die is vereist
                voor de afhandeling van uw inschrijving, en voorts daarna tot
                het moment waarop eventuele aansprakelijkheden die voortvloeien
                uit uw inschrijving zijn vervallen.{' '}
              </li>
              <li>
                Als u het betreffende hokje heeft aangevinkt: de afhandeling van
                uw aanmelding voor de commerciële nieuwsbrief, om u op de hoogte
                te kunnen houden van nieuwe evenementen en het laatste nieuws
                van Ironhack. Uw gegevens worden bewaard totdat u uw toestemming
                intrekt.{' '}
              </li>
            </ul>
            De persoonlijke gegevens die worden verstrekt{' '}
            <strong>via het inschrijfformulier als samenwerkingsbedrijf</strong>{' '}
            worden door Ironhack verwerkt voor de volgende doeleinden:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                De afhandeling van de inschrijving van de onderneming waar u
                werkzaam bent als samenwerkingsbedrijf van Ironhack. Uw gegevens
                worden voor dit doel bewaard gedurende de tijd van beheer van de
                onderneming als samenwerkingsbedrijf, en voorts daarna tot het
                moment waarop eventuele aansprakelijkheden die voortvloeien uit
                de inschrijving zijn vervallen.{' '}
              </li>
            </ul>
            De persoonlijke gegevens die door de gebruiker worden verstrekt{' '}
            <strong>via het sollicitatieformulier voor een vacature</strong>,
            worden door Ironhack verwerkt voor de volgende doeleinden:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                De evaluatie van uw sollicitatie voor het selectieproces en,
                indien van toepassing, het ondernemen van de nodige actie in het
                kader van de sollicitatieprocedure of van toekomstige procedures
                die voor u interessant zouden kunnen zijn. Tenzij anders
                vermeld, worden uw gegevens bewaard gedurende de periode van één
                jaar na uw sollicitatie. Het is daarom voor u zaak de gegevens
                up-to-date te houden, want deze kunnen worden gebruikt om u
                mogelijk interessante, nieuwe vacatures van Ironhack toe te
                sturen.{' '}
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INSCHRIJVING VOOR IRONHACK</Text2>
          <Text3 as="p">
            Als u zich wilt inschrijven voor een Ironhack Bootcamp, moet u de
            volgende informatie indienen nadat u hebt geklikt op de knop “
            <a href="https://www.ironhack.com/nl/cursussen/aanmelden">
              Apply now
            </a>
            ” (inschrijven) van deze Website:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Naam en voornaam;</li>
              <li>Telefoonnummer;</li>
              <li>Een actief e-mailadres;</li>
              <li>Geslacht;</li>
              <li>Referentie; en</li>
              <li>
                een korte beschrijving waarom u geïnteresseerd bent in Ironhack.
              </li>
            </ul>
            Tijdens het registratieproces kunt u ook optionele aanvullende
            informatie verstrekken, zodat wij u een meer gepersonaliseerde
            ervaring kunnen bieden wanneer u deze website gebruikt, maar die
            keuze laten wij aan u over. Nadat u de gevraagde informatie hebt
            ingediend, bepalen wij of uw inschrijving al dan niet wordt
            geaccepteerd. Indien uw inschrijving wordt goedgekeurd, ontvangt u
            een e-mail met de stappen die nodig zijn om het inschrijvingsproces
            te voltooien. Als u de bovenstaande informatie niet verstrekt,
            zullen wij uw inschrijvingsformulier niet kunnen verwerken en u onze
            diensten niet op de meest geschikte manier kunnen aanbieden.
          </Text3>
          <Text3 as="p">
            U bent verantwoordelijk voor het naleven van deze voorwaarden
            wanneer u deze website bezoekt. Het is uw verantwoordelijkheid om de
            apparatuur en technische diensten te verkrijgen en te onderhouden
            die nodig zijn om toegang te krijgen tot en gebruik te maken van
            deze Website, en om alle bijbehorende kosten te betalen. Het is ook
            uw verantwoordelijkheid om de informatie die u aan ons verstrekt
            tijdens uw inschrijving voor een Ironhack Bootcamp vertrouwelijk te
            houden. Als u van mening bent dat uw persoonlijke informatie of
            beveiliging met betrekking tot deze website op enigerlei wijze is
            geschonden, moet u ons hiervan onmiddellijk op de hoogte stellen.
            Als u meer informatie nodig hebt, bezoek dan de pagina
            <a href="https://www.ironhack.com/nl/wetgeving/juridische-kennisgeving">
              Privacybeleid
            </a>
            .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COOKIEBELEID</Text2>
          <Text3 as="p">
            Klik{' '}
            <Link href="https://cdn.ironhack.com/marketing/IH_Coookies_Policy.docx">
              hier
            </Link>{' '}
            om ons cookiebeleid te bekijken
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            WAT IS DE WETTELIJKE GRONDSLAG VOOR DE VERWERKING VAN DE
            PERSOONSGEGEVENS VAN DE GEBRUIKER
          </Text2>
          <Text3 as="p">
            <ul>
              <li>
                De legitimiteit van de gegevensverwerking in verband met
                behandeling van vragen, suggesties en/of klachten berust op de
                toestemming die de gebruiker heeft verleend bij de indiening van
                zijn verzoek.{' '}
              </li>
              <li>
                De legitimiteit van de gegevensverwerking in verband met de
                aanmelding voor de commerciële nieuwsbrief om op de hoogte te
                blijven van nieuwe evenementen en het laatste nieuws van
                Ironhack berust op de toestemming die de gebruiker heeft
                verleend bij de indiening van zijn verzoek.{' '}
              </li>
              <li>
                De legitimiteit van de gegevensverwerking in verband met de
                inschrijving op de cursussen van Ironhack berust op de
                uitvoering van de verbintenis tussen Ironhack en de gebruiker,
                zodat toestemming van de gebruiker niet nodig is.{' '}
              </li>
              <li>
                De legitimiteit van de gegevensverwerking in verband met de
                inschrijving als samenwerkingsbedrijf van Ironhack, berust op de
                uitvoering van de verbintenis tussen beide, zodat toestemming
                niet nodig is.{' '}
              </li>
              <li>
                De verwerking van de gegevens met betrekking tot de aanmelding
                voor een sollicitatieprocedure is gebaseerd op de toestemming
                van de Gebruiker.
              </li>
            </ul>
            Houd er rekening mee dat u uw toestemming te allen tijde kunt
            intrekken, maar dat intrekking van uw toestemming geen invloed heeft
            op de rechtmatigheid van de verwerking van gegevens die voorafgaand
            aan de herroeping werd uitgevoerd.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">BESTEMMING</Text2>
          <Text3 as="p">Uw gegevens kunnen worden doorgegeven aan: </Text3>
          <Text3 as="p">
            <ul>
              <li>de overheid, in de wettelijk voorziene gevallen; </li>
              <li>
                rechtbanken en veiligheidsinstanties, in de wettelijk verplichte
                gevallen;{' '}
              </li>
              <li>
                dienstenverleners voor informatieverwerking voor
                marketingdoeleinden:
                <ul>
                  <li>Mailchimp</li>
                  <li>Pipedrive</li>
                  <li>Google Analytics</li>
                  <li>Hotjar</li>
                  <li>Facebook Custom Audience</li>
                </ul>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INTERNATIONALE OVERDRACHT</Text2>
          <Text3 as="p">
            De verzamelde informatie kan worden doorgestuurd naar:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Ironhack campussen in Mexico, VS &amp; Brazilië</li>
              <li>Mailchimp Inc. (VS)</li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">AANSPRAKELIJKHEID VAN DE GEBRUIKER</Text2>
          <Text3 as="p">De Gebruiker:</Text3>
          <Text3 as="p">
            <ul>
              <li>
                Garandeert dat hij ouder is dan 18 jaar en dat de gegevens die
                hij aan Ironhack verstrekt naar waarheid, nauwkeurig, volledig
                en up-to-date zijn ingevuld. Hiertoe staat de Gebruiker ervoor
                in dat alle gegevens waarheidsgetrouw zijn en dat de verstrekte
                informatie voldoende up-to-date wordt gehouden en te allen tijde
                overeenkomt met zijn werkelijke situatie.{' '}
              </li>
              <li>
                Hij garandeert dat hij eventuele derden van wie hij desgewenst
                de persoonsgegevens heeft verstrekt, op de hoogte heeft gesteld
                van de inhoud van dit document. Hij garandeert bovendien dat
                hij, om die gegevens aan Ironhack te verstrekken voor de
                aangegeven doeleinden, van die derden toestemming heeft
                gekregen.
              </li>
              <li>
                Hij zal aansprakelijk zijn voor eventuele onjuiste of
                onnauwkeurige, via de Website verstrekte informatie, en voor
                eventuele directe of indirecte schade die hij daardoor aan
                Ironhack of aan derden zou kunnen berokkenen.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMMERCIËLE KENNISGEVINGEN</Text2>
          <Text3 as="p">
            Een van de doeleinden waarvoor Ironhack de door Gebruikers
            verstrekte persoonsgegevens verwerkt, is het langs elektronische weg
            verzenden van informatie over producten, diensten, reclame,
            aanbiedingen, evenementen of nieuws dat relevant is voor de
            Gebruikers. Bij dergelijke communicatie is elke mededeling van die
            aard uitsluitend en alleen gericht tot de Gebruiker, die daartoe
            uitdrukkelijk zijn toestemming heeft gegeven.
          </Text3>
          <Text3 as="p">
            Indien de Gebruiker niet langer reclame of commerciële informatie
            van Ironhack wenst te ontvangen, kan hij zich afmelden van de
            verzendlijst door een e-mail te sturen naar:{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">UW RECHTEN</Text2>
          <Text3 as="p">
            Als gebruiker kunt u te allen tijde, kosteloos contact opnemen met
            Ironhack door zich schriftelijk te richten tot Calle Castelló, 115B,
            Local 7, 28006, Madrid, met het kenmerk "Data Protection", of door
            een e-mail te sturen naar{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>, met
            bijvoeging van een kopie van uw identiteitsbewijs:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>om de verleende toestemmingen in te trekken; </li>
              <li>
                om te vragen of Ironhack al dan niet uw persoonsgegevens
                verwerkt;{' '}
              </li>
              <li>om toegang tot te krijgen tot uw persoonlijke gegevens; </li>
              <li>om onnauwkeurige of onvolledige gegevens te corrigeren; </li>
              <li>om te verzoeken om verwijdering van uw gegevens; </li>
              <li>
                om Ironhack een beperking te verzoeken in de verwerking van uw
                gegevens wanneer sprake is van één van de daartoe strekkende
                voorwaarden van de privacywetgeving;{' '}
              </li>
              <li>
                in bepaalde gevallen en om redenen die verband houden met uw
                specifieke situatie met betrekking tot de verwerking van uw
                gegevens (met name voor commerciële doeleinden door Ironhack):
                om bezwaar te maken tegen de verwerking van uw persoonsgegevens.
                Ironhack zal dan stoppen met de verwerking van die gegevens,
                behalve in geval van dwingende, gewettigde redenen, of voor het
                instellen van of zich verdedigen tegen mogelijke claims;
              </li>
              <li>
                om te verzoeken om persoonlijk contact om uw standpunt weer te
                kunnen geven en bezwaar te kunnen maken tegen op
                geautomatiseerde wijze genomen beslissingen van Ironhack;{' '}
              </li>
              <li>om te verzoeken om overdracht van uw gegevens; </li>
              <li>
                om een klacht in te dienen over de bescherming van uw
                persoonsgegevens bij de desbetreffende toezichthoudende
                autoriteit (in het geval van Spanje: Agencia Española de
                Protección de Datos).{' '}
              </li>
            </ul>
            De uitoefening van voornoemde rechten is afhankelijk van de naleving
            van de vereisten als voorzien in de op elk moment geldende
            wetgeving.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">VEILIGHEIDSMAATREGELEN</Text2>
          <Text3 as="p">
            Ironhack zal de gegevens van de gebruiker te allen tijde
            vertrouwelijk behandelen en de geheimhoudingsplicht ten aanzien van
            de gegevens van de gebruiker in acht nemen, in overeenstemming met
            de bepalingen van de toepasselijke wetgeving, en de technische en
            organisatorische maatregelen nemen die nodig zijn om de veiligheid
            van de gegevens van de gebruiker te waarborgen, en om
            niet-geautoriseerde wijzigingen, verliezen of ongeoorloofde toegang
            of behandeling te voorkomen, in overeenstemming met de stand van de
            techniek, de aard van de opgeslagen gegevens en de risico's waaraan
            deze zijn blootgesteld.
          </Text3>
          <Text3 as="p">Laatste update: 10-8-2018</Text3>
          <Text3 as="p">
            <strong>
              Copyright © Ironhack School S.L. Alle rechten voorbehouden.{' '}
            </strong>
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "nl" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('nl')(PrivacyPolicy);
